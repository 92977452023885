<template>
    <div class="login-content">
        <el-card v-if="!forgotPassword" class="login-card">
            <div style="display: flex;flex-direction: column;justify-content: center;align-items: center;">
                <img class="logo" alt="Vue logo" src="../../assets/logo.png">
            </div>
            <div style="display: flex;flex-direction: column;justify-content: center;align-items: center;">
                <el-radio-group v-model="systemType" @change="selectSystemType">
                    <el-radio-button label="门禁"></el-radio-button>
                    <el-radio-button label="门柜"></el-radio-button>
                    <el-radio-button label="管理员"></el-radio-button>
                </el-radio-group>
            </div>
            <el-input class="userName" placeholder="用户名" v-model="userName"/>
            <el-input class="password" placeholder="密码" show-password v-model="password"/>
            <div class="verifyCode-content">
                <el-input class="verifyCode" placeholder="验证码" v-model="verifyCode"/>
                <el-image class="verifyCodeImage" :src="verifyCodeBase64" @click="loadVerifyCode()"></el-image>
            </div>
            <div class="requestLogin-content" @click="requestLogin">
                <el-button class="login" type="primary" :loading="isLoading">登录</el-button>
            </div>

            <div class="forgotPassword">
                <el-link :underline="false" @click="forgotPassword=true">忘记密码</el-link>
            </div>
        </el-card>
        <el-card v-if="forgotPassword" class="forgotPassword-card">
            <div style="display: flex;flex-direction: column;justify-content: center;align-items: center;">
                <h3>重置密码</h3>
            </div>
            <el-input class="userName" placeholder="用户名" v-model="userName"/>
            <el-input class="resetCode" placeholder="重置码" v-model="resetCode"/>
            <div class="verifyCode-content">
                <el-input class="verifyCode" placeholder="验证码" v-model="verifyCode"/>
                <el-image class="verifyCodeImage" :src="verifyCodeBase64" @click="loadVerifyCode()"></el-image>
            </div>
            <div class="requestLogin-content" @click="resetPassword">
                <el-button class="login" type="primary" :loading="isLoading">重置密码</el-button>
            </div>
            <div class="forgotPassword">
                <el-link :underline="false" @click="forgotPassword=false">返回登录</el-link>
            </div>
        </el-card>

    </div>
</template>

<script>
    export default {
        data() {
            return {
                systemType: '门禁',
                userName: '',
                password: '',
                isLoading: false,
                verifyCode: '',
                verifyCodeBase64: '',
                checkCode: '',
                forgotPassword:false,
                resetCode:''
            }
        },
        mounted() {
            const systemType = localStorage.getItem('systemType')
            if (systemType === 0) {
                this.systemType = '门禁'
            }else
            if (systemType === 1) {
                this.systemType = '门柜'
            }else
            if (systemType === 2) {
                this.systemType = '管理员'
            }else {
                this.systemType = '门禁'
                localStorage.setItem('systemType',0)
            }

            this.loadVerifyCode()

            var monthStartAndEnd = this.getMonthTimestamp()

            if (localStorage.getItem('statisticsStartDate') == null) {
                localStorage.setItem('statisticsStartDate', monthStartAndEnd[0])
            }
            if (localStorage.getItem('statisticsStopDate') == null) {
                localStorage.setItem('statisticsStopDate', monthStartAndEnd[1])
            }

            if (localStorage.getItem('recordStartDate') == null) {
                localStorage.setItem('recordStartDate', monthStartAndEnd[0])
            }
            if (localStorage.getItem('recordStopDate') == null) {
                localStorage.setItem('recordStopDate', monthStartAndEnd[1])
            }

            if (localStorage.getItem('doorRecordStartDate') == null) {
                localStorage.setItem('doorRecordStartDate', monthStartAndEnd[0])
            }
            if (localStorage.getItem('doorRecordStopDate') == null) {
                localStorage.setItem('doorRecordStopDate', monthStartAndEnd[1])
            }

            if (localStorage.getItem('openBoxStartDate') == null) {
                localStorage.setItem('openBoxStartDate', monthStartAndEnd[0])
            }
            if (localStorage.getItem('openBoxStopDate') == null) {
                localStorage.setItem('openBoxStopDate', monthStartAndEnd[1])
            }

            if (localStorage.getItem('userLoginRecordStartDate')==null){
                localStorage.setItem('userLoginRecordStartDate',monthStartAndEnd[0])
            }
            if (localStorage.getItem('userLoginRecordStopDate')==null){
                localStorage.setItem('userLoginRecordStopDate',monthStartAndEnd[1])
            }
        },
        methods: {
            selectSystemType(values) {
                if (values === '门禁') {
                    localStorage.setItem('systemType', 0)
                }
                if (values === '门柜') {
                    localStorage.setItem('systemType', 1)
                }
                if (values === '管理员') {
                    localStorage.setItem('systemType', 2)
                }

            },
            requestLogin() {
                if (!this.userName) {
                    this.$message.error('用户名不能为空！');
                    return
                }
                if (!this.password) {
                    this.$message.error('密码不能为空！');
                    return
                }
                this.isLoading = !this.isLoading
                setTimeout(() => {
                    axios.post('/login', {
                        userName: this.userName,
                        password: this.password,
                        verifyCode: this.verifyCode,
                        checkCode: this.checkCode,
                        type: localStorage.getItem("systemType")
                    }).then((res) => {
                        this.isLoading = !this.isLoading
                        if (res.data.code === 1) {
                            localStorage.setItem('token', res.data.data.token)
                            if (this.systemType === '门禁') {
                                this.$router.replace('/access_home')
                            }
                            if (this.systemType === '门柜') {
                                this.$router.replace('/door_home')
                            }
                            if (this.systemType === '管理员') {
                                this.$router.replace('/admin_user')
                            }
                        } else {
                            this.$message.error(res.data.msg);
                            this.loadVerifyCode()
                        }
                    }).catch((error) => {
                        this.isLoading = !this.isLoading
                        this.$message.error('请求服务器失败!');
                    })
                })
            },
            resetPassword(){
                this.isLoading = !this.isLoading
                setTimeout(()=>{
                    axios.post('/resetPassword',{
                        userName:this.userName,
                        resetCode:this.resetCode,
                        verifyCode:this.verifyCode,
                        checkCode:this.checkCode
                    })
                    .then((res)=>{
                        this.isLoading = !this.isLoading
                        this.loadVerifyCode()
                        if (res.data.code===1){
                            this.$alert(res.data.msg, {
                                confirmButtonText: '确定',
                                callback: action => {
                                    this.forgotPassword=!this.forgotPassword

                                }
                            });
                        }else {
                            this.$message.error(res.data.msg);
                        }
                    })
                    .catch((error)=>{
                        this.isLoading = !this.isLoading
                        this.loadVerifyCode()
                        this.$message.error('请求服务器失败!');
                    })
                })
            },
            loadVerifyCode() {
                setTimeout(() => {
                    axios.get('/getVerifyCode')
                        .then((res) => {
                            if (res.data.code === 1) {
                                this.verifyCodeBase64 = res.data.data.base64
                                this.checkCode = res.data.data.checkCode
                            } else {
                                this.$message.error(res.data.msg);
                            }
                        })
                })
            },
            getMonthTimestamp() {
                var date = new Date()
                var year = date.getFullYear()
                var month = date.getMonth()
                return [new Date(year, month, 1), new Date(year, month + 1, 0)]
            },

            handleClose(done) {
                done()
            },
        }

    }
</script>

<style>
    .login-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: fixed;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background-image: url(../../assets/bg.webp);
        background-size: cover;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        /* filter: blur(3px); */
    }

    .login-card {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 340px;
        background: #ffffff;
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 20px;
        padding-bottom: 40px;
        border-radius: 10px;
    }

    .logo {
        width: 6.25rem;
        height: 6.25rem;
        border-radius: 7.5rem;
    }

    .userName {
        margin-top: 20px;
    }

    .resetCode{
        margin-top: 20px;
    }

    .password {
        margin-top: 20px;
    }

    .login {
        width: 100%;
    }

    .verifyCode-content {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 20px;
        width: 100%;
        height: 60px;

    }

    .verifyCodeImage {
        margin-left: 20px;
        width: 100px;
        height: auto;
    }

    .requestLogin-content {
        margin-top: 20px;
        width: 100%;
    }

    .forgotPassword {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-top: 20px;
    }

</style>
